/************************************************

Stylesheet: Global Stylesheet

*************************************************/
@import url('https://fonts.googleapis.com/css?family=Alegreya:400,700|Questrial');
$teal: #3aabb4;
$header_font: 'Questrial', sans-serif;
$body_font: 'Alegreya', serif;

body {
	background-color: #25323d;
	color: $white;
	font-family: $body_font;
	font-size: rem-calc(19);
}

h1, .h1 {
	text-transform: uppercase;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	color: $white;
	font-family: $header_font;
}

/***  Navigation  ***/
header.header {
	top: 0;
	left: 0;
	width: 100%;
	@include breakpoint(medium) {
		position: absolute;
	}
	.top-bar {
		.top-bar-left {
			ul.menu {
				align-items: center;
				li {
					a {
						font-family: $header_font;
						text-transform: uppercase;
						letter-spacing: 1px;
						color: $white;
						svg {
							width: rem-calc(60);
							.st0{
								fill:$white;
							}
						}
					}
					&.logo {
						a {
							padding-left: 0;
							@include breakpoint(medium only) {
								padding: 0.7rem 0;	
							}
						}
					}
				}
			}
		}
	}
}
.off-canvas {
	ul.menu {
		padding-top: rem-calc(20);
		li {
			&.logo {
				display: none;
			}
			a {
				font-family: $header_font;
				text-transform: uppercase;
				letter-spacing: 1px;
				color: $black;
			}
		}
	}
}
/* Home Page */
body.home {
	height: 100vh;
	background-image: url(../img/bg-home2.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	#home-container {
		height: 100vh;
		align-items: flex-end;
		display: flex;
		.cell {
			padding-bottom: 15%;
			@include breakpoint(medium) {
				padding-bottom: 5%;
			}
		}
	}
	footer.footer {
		position: absolute;
		bottom: 0;
		right: 0;
	}
}
body.resume {
	// background-image: url(../img/bg-resume.jpg);
	// background-position: top center;
	// background-size: auto;
	// background-repeat: no-repeat;
	// background-color: #111b25;
}
.grid-container {
	.inner-content {
		@include breakpoint(medium) {
			padding-top: rem-calc(82);
		}
		h1.page-title {
			border-bottom: solid 1px;
			margin-bottom: rem-calc(32);
		}
		.entry-content {
			.resume-section {
				h2 {
					text-transform: uppercase;
					text-decoration: underline;
					letter-spacing: rem-calc(4);
					@include breakpoint(medium) {
						font-size: rem-calc(32);
						font-weight: bold;
					}
				}
				.resume-item {
					margin-bottom: 2rem;
					.resume-title {
					    text-transform: uppercase;
					    margin-bottom: 0;
					    line-height: 1.2;
					    font-weight: bold;
					    @include breakpoint(medium) {
						    font-size: rem-calc(22);
						}
					}
					.resume-location {
					    margin-bottom: 0;
					    line-height: 1.2;
					    @include breakpoint(medium) {
						    font-size: rem-calc(20);
						}
					}
					.resume_date {
					    font-style: italic;
					    margin-bottom: .5rem;
					}
					.resume-concentration {
					    margin-bottom: 0;
					}
				}
			}
		}
	}
}
/* Footer */
footer.footer {
	.footer_bottom {
		p.copyright {
			font-size: rem-calc(14);
			a {
				color: $white;
			}
		}
	}
}